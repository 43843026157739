<script setup lang="ts">
import { reactive, ref } from "vue";

import { Caption, Input } from "@/shared/ui-v2";

type Emits = {
  change: [value: string[]];
  changeItemByIndex: [index: number, value: string];
  focus: [];
};

interface Props {
  count: number;
  error: {
    isExists: boolean;
    message: string;
  };
  value: string[];
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const NUMBER_VALIDATOR = /^[0-9]$/;
const MAX_NUMBER_OF_CHARACTERS = 1;

const inputRefs = reactive(
  Object.fromEntries(Array.from({ length: props.count }, (_, index) => [index + 1, null])) as {
    [p: string]: Nullable<HTMLInputElement>;
  },
);

const isPasted = ref(false);

const onChange = (value: string[]) => emit("change", value);
const onChangeItemByIndex = (index: number, value: string) => emit("changeItemByIndex", index, value);
const onFocus = () => emit("focus");

const changeIsPasted = (value: boolean) => {
  isPasted.value = value;
};

const handleDelete = (index: number) => {
  if (index === 0) {
    return;
  }

  if (props.value[index]) {
    onChangeItemByIndex(index, "");
    return;
  }

  handleFocus(index - 1);
  onChangeItemByIndex(index - 1, "");
};

const handleFocus = (index: number) => {
  if (index + 1 > props.count) {
    return;
  }

  inputRefs[index]?.focus();
};

const handleInput = (event: Event, index: number) => {
  let value = (event as Event & { target: HTMLInputElement }).target.value;

  if (isPasted.value || isAutocomplete(value)) {
    const digit = props.value[index];

    if (digit) {
      const regex = new RegExp(`^${digit}|${digit}$`, "g");

      value = value.replace(regex, "");
    }

    changeIsPasted(false);
    handlePaste(value);
  }

  if (!NUMBER_VALIDATOR.test(value)) {
    value = value.slice(0, value.length - 1);
    // eslint-disable-next-line no-param-reassign
    (event as Event & { target: HTMLInputElement }).target.value = value;
    return;
  }

  handleFocus(index + 1);
  onChangeItemByIndex(index, value);
};

const handlePaste = (value: string) => {
  if (!Number.isInteger(Number(value))) {
    return;
  }

  const code = value.trim().slice(0, props.count);

  handleFocus(code.length - 1);
  onChange(
    Array(props.count)
      .fill("")
      .map((_, i) => code.charAt(i) || ""),
  );
};

const isAutocomplete = (value: string) => value.length > MAX_NUMBER_OF_CHARACTERS + 1;

const setRef = (el: HTMLInputElement, index: number) => {
  inputRefs[index] = el;
};
</script>

<template>
  <!-- @vue-skip -->
  <div :class="$style.root">
    <div :class="$style.container">
      <Input
        v-for="(digit, index) of value"
        :key="index"
        :ref="(el) => setRef(el?.ref as HTMLInputElement, index)"
        input-mode="numeric"
        :label-class="$style.label"
        :value="digit"
        @delete="handleDelete(index)"
        @focus="onFocus"
        @input="(event) => handleInput(event, index)"
        @paste="changeIsPasted(true)"
      />
    </div>
    <Caption
      v-if="error.isExists"
      color="negative"
    >
      {{ error.message }}
    </Caption>
  </div>
</template>

<style module lang="postcss">
.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.container {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-8);
}

.label {
  padding: var(--spacing-0) !important;

  & input {
    text-align: center;
  }
}
</style>
