<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import type { Deposit } from "@/entities/deposit";
import { useDepositStore } from "@/entities/deposit";
import { SectionButtonV2, SectionWrapperV2 } from "@/entities/section";
import { buildQuery } from "@/entities/transaction";
import { routeNamesV2 } from "@/shared/constants";
import { Paragraph } from "@/shared/ui-v2";

const { t } = useI18n();
const router = useRouter();

const { apiResponse } = storeToRefs(useDepositStore());

const response = computed(
  () => apiResponse.value as Omit<Deposit["apiResponse"], "data"> & { data: Record<string, unknown> },
);

const redirect = async () => {
  const url = buildQuery(response.value);
  window.open(url);
  await router.push({ name: routeNamesV2.depositCompletePage });
};
</script>

<template>
  <SectionWrapperV2>
    <Paragraph :class="$style.text">
      {{ t("deposit.proceed.text") }}
    </Paragraph>
    <SectionButtonV2
      :class="$style.button"
      color="accent"
      @click="redirect"
    >
      {{ t("deposit.proceed.button") }}
    </SectionButtonV2>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.button {
  margin-top: auto;
}
</style>
