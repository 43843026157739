import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";

import { i18n } from "@/app/plugins";
import type { PaymentGroup, PaymentMethod } from "@/entities/payment-method";
import {
  cardGroups,
  cardViews,
  defaultLimits,
  fetchPaymentMethods as fetchPaymentMethodsRequest,
  fetchWithdrawalMethods as fetchWithdrawalMethodsRequest,
  paymentMethodsWithV3Recaptcha,
} from "@/entities/payment-method";
import { fetchPaymentSnippet as fetchPaymentSnippetRequest, useUserStore } from "@/entities/user";
import { useRequest } from "@/shared/composables";

const NAMESPACE = "payment-method";

export const usePaymentMethodStore = defineStore(NAMESPACE, () => {
  const { country, user } = storeToRefs(useUserStore());

  const {
    isLoading: isFetchingPaymentMethods,
    data: paymentMethodsResponse,
    execute: loadPaymentMethods,
  } = useRequest(() =>
    fetchPaymentMethodsRequest({
      country: country.value,
      currency: user.value.currency,
    }),
  );

  const {
    isLoading: isFetchingWithdrawalMethods,
    data: withdrawalMethodsResponse,
    execute: loadWithdrawalMethods,
  } = useRequest(() =>
    fetchWithdrawalMethodsRequest({
      country: country.value,
      currency: user.value.currency,
    }),
  );

  const {
    isLoading: isFetchingPaymentSnippet,
    data: paymentSnippetResponse,
    execute: loadPaymentSnippet,
  } = useRequest(() =>
    fetchPaymentSnippetRequest({
      country: country.value,
      currency: user.value.currency,
    }),
  );

  const initialPaymentMethods = ref<PaymentMethod[]>([]);
  const paymentGroup = ref<Nullable<PaymentGroup>>(null);
  const paymentMethod = ref<Nullable<PaymentMethod>>(null);
  const paymentMethods = ref<PaymentMethod[]>([]);

  const limits = computed(() => paymentMethod.value?.amount_rules?.[user.value.currency] ?? defaultLimits);

  const isBankTransfer = computed(
    () =>
      paymentMethod.value?.cardViewType === cardViews.bankTransfer ||
      paymentMethod.value?.name === "nigerian_bank_transfer",
  );
  const isPaymentMethodWithV3Recaptcha = computed(() =>
    paymentMethodsWithV3Recaptcha.includes(paymentMethod.value!.name),
  );

  const paymentGroups = computed<PaymentGroup[]>(() =>
    Object.values(cardGroups).map((group) => ({
      label: i18n.global.t(`paymentGroup.${group}`),
      name: group,
      paymentMethods: paymentMethods.value.filter(({ category }) => category === group),
    })),
  );
  const paymentLabel = computed(() => paymentMethod.value?.text[user.value.lang] ?? paymentMethod.value?.text.en ?? "");
  const filteredPaymentGroups = computed(() =>
    paymentGroups.value.filter(({ name }) => sortedPaymentMethods.value.some(({ category }) => category === name)),
  );
  const filteredPaymentMethods = computed(() =>
    sortedPaymentMethods.value.filter(({ category }) => !paymentGroups.value.some(({ name }) => name === category)),
  );
  const sortedPaymentMethods = computed(() => paymentMethods.value.sort((a, b) => a.position - b.position));

  const changeInitialPaymentMethods = (items: PaymentMethod[]) => {
    initialPaymentMethods.value = items;
  };

  const changePaymentGroup = (data: Nullable<PaymentGroup>) => {
    paymentGroup.value = data;
  };

  const changePaymentMethod = (data: Nullable<PaymentMethod>) => {
    paymentMethod.value = data;
  };

  const changePaymentMethods = (items: PaymentMethod[]) => {
    paymentMethods.value = items;
  };

  const fetchPaymentMethods = async () => {
    try {
      await loadPaymentMethods();
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  const fetchWithdrawalMethods = async () => {
    try {
      await loadWithdrawalMethods();
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  const fetchPaymentSnippet = async () => {
    try {
      await loadPaymentSnippet();
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  return {
    initialPaymentMethods,
    isFetchingPaymentMethods,
    isFetchingPaymentSnippet,
    isFetchingWithdrawalMethods,
    limits,
    paymentGroup,
    paymentGroups,
    paymentLabel,
    paymentMethod,
    paymentMethodsResponse,
    paymentSnippetResponse,
    withdrawalMethodsResponse,
    filteredPaymentGroups,
    filteredPaymentMethods,
    sortedPaymentMethods,
    isBankTransfer,
    isPaymentMethodWithV3Recaptcha,
    changeInitialPaymentMethods,
    changePaymentGroup,
    changePaymentMethod,
    changePaymentMethods,
    fetchPaymentMethods,
    fetchPaymentSnippet,
    fetchWithdrawalMethods,
  };
});
