<script setup lang="ts">
import { storeToRefs } from "pinia";
import { toRef } from "vue";

import { getPaymentIcon, usePaymentMethodStore } from "@/entities/payment-method";
import { useThemeStore } from "@/entities/theme";
import type { Transaction, TransactionStatusKey } from "@/entities/transaction";
import { TransactionStatus, useTransaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { useFormStore } from "@/features/form";
import { Icon, Typography } from "@/shared/ui";
import type { TransactionContent, Translations } from "@/widgets/transaction";

interface Props {
  content: TransactionContent;
  transaction: Transaction;
  transactionStatus: TransactionStatusKey;
  translations: Translations;
}

const props = defineProps<Props>();

const TICKET_CIRCLES_COUNT = 15;

const { paymentLabel, paymentMethod } = storeToRefs(usePaymentMethodStore());

const { country, user } = storeToRefs(useUserStore());

const { isLegacyDesign, theme } = storeToRefs(useThemeStore());

const { amount } = storeToRefs(useFormStore());

const { datetime, formattedAmount } = useTransaction({
  amount,
  isLegacyDesign,
  paymentMethod,
  transaction: toRef(() => props.transaction),
  transactionStatus: toRef(() => props.transactionStatus),
  user,
});
</script>

<template>
  <div class="mx-auto flex h-full w-full max-w-2xl flex-col justify-center">
    <div class="relative h-max rounded-3xl bg-gray-50 dark:bg-white">
      <div class="absolute -top-20 z-10 flex w-full justify-center">
        <component
          :is="content.component"
          class="w-40"
        />
      </div>
      <div class="px-6 pt-17 text-center text-gray-900 dark:text-onyx">
        <Typography
          class="mb-3 text-3xl"
          size="auto"
          variant="h3"
          weight="bold"
        >
          {{ content.title }}
        </Typography>
        <Typography
          class="mb-7 whitespace-pre-line text-center text-2md leading-5.5 text-gray-350 dark:text-pearl"
          size="auto"
        >
          {{ content.text }}
        </Typography>
      </div>
      <div class="relative flex w-full items-center">
        <div class="absolute -left-2.5 h-5 w-5 rounded-full bg-white dark:bg-onyx" />
        <hr class="w-full border-dashed border-white dark:border-onyx" />
        <div class="absolute -right-2.5 h-5 w-5 rounded-full bg-white dark:bg-onyx" />
      </div>
      <div class="grid gap-6 px-6 pb-9 pt-8">
        <div class="grid gap-0.5">
          <Typography
            class="text-xs text-gray-350 dark:text-pearl"
            size="auto"
          >
            {{ translations.date }}
          </Typography>
          <Typography class="text-gray-900 dark:text-onyx">{{ datetime[0] }} {{ datetime[1] }}</Typography>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-0.5">
            <Typography
              class="text-xs text-gray-350 dark:text-pearl"
              size="auto"
            >
              {{ translations.paymentMethod }}
            </Typography>
            <Typography class="text-gray-900 dark:text-onyx">
              {{ paymentLabel }}
            </Typography>
          </div>
          <Icon
            class="h-8 w-8"
            :name="
              getPaymentIcon({
                country,
                name: paymentMethod?.name ?? 'none',
                theme,
                transaction,
                type: 'complete',
              })
            "
          />
        </div>
        <div class="flex flex-col gap-0.5">
          <Typography
            class="text-xs text-gray-350 dark:text-pearl"
            size="auto"
          >
            {{ translations.amount }}
          </Typography>
          <div class="flex items-center justify-between">
            <Typography
              class="text-2.25xl leading-8.5 text-gray-900 dark:text-onyx"
              size="auto"
              weight="bold"
            >
              {{ formattedAmount }}
            </Typography>
            <TransactionStatus :status="transactionStatus" />
          </div>
        </div>
      </div>
      <div class="absolute -bottom-2 left-0 flex w-full justify-center">
        <div class="flex w-[95%] justify-between">
          <div
            v-for="number of TICKET_CIRCLES_COUNT"
            :key="number"
            class="aspect-square w-[4%] rounded-full bg-white dark:bg-onyx"
          />
        </div>
      </div>
    </div>
  </div>
</template>
