<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { SectionButtonV2, SectionWrapperV2 } from "@/entities/section";
import { routeNamesV2 } from "@/shared/constants";
import { Container, IconBox, Paragraph, Title } from "@/shared/ui-v2";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
</script>

<template>
  <section>
    <Container>
      <SectionWrapperV2>
        <div :class="$style.wrapper">
          <IconBox
            name="error"
            size="xl"
          />
          <Title
            :class="$style.title"
            color="primary"
            size="l"
          >
            {{ t("error.title") }}
          </Title>
          <Paragraph
            color="secondary"
            size="s"
          >
            {{ t("error.text") }}
          </Paragraph>
        </div>
        <SectionButtonV2
          @click="router.push({ name: (route.query.backRoute as string | undefined) ?? routeNamesV2.depositListPage })"
        >
          {{ t("button.refresh") }}
        </SectionButtonV2>
      </SectionWrapperV2>
    </Container>
  </section>
</template>

<style module lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.title {
  margin-bottom: var(--spacing-4);
}
</style>
