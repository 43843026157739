import type { Router } from "vue-router";

const TIMEOUT_DURATION = 1_000 * 30;

export const fetchData = async (
  requests: Promise<void>[],
  route: {
    back: string;
    error: string;
  },
  router: Router,
) => {
  try {
    await Promise.race([
      Promise.all(requests),
      new Promise((_, reject) => setTimeout(() => reject(new Error("Request timeout")), TIMEOUT_DURATION)),
    ]);
  } catch (error) {
    await router.push({
      name: route.error,
      query: {
        backRoute: route.back,
      },
    });
    throw new Error((error as Error).message);
  }
};
