<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, h, onMounted, toRef } from "vue";

import { usePaymentMethodStore } from "@/entities/payment-method";
import { SectionWrapperV2 } from "@/entities/section";
import { useThemeStore } from "@/entities/theme";
import type { Transaction, TransactionStatusKey } from "@/entities/transaction";
import { transactionStatuses, useTransaction } from "@/entities/transaction";
import { useUserStore } from "@/entities/user";
import { useFormStore } from "@/features/form";
import ProcessImg from "@/shared/assets/images/transaction-statuses/process.png";
import SuccessImg from "@/shared/assets/images/transaction-statuses/success.png";
import WarningImg from "@/shared/assets/images/transaction-statuses/warning.png";
import { bridgeService } from "@/shared/services";
import { Spinner } from "@/shared/ui-v2";
import type { TranslationsV2, TransactionContentV2 } from "@/widgets/transaction";
import { PaymentStatement, TransactionButtonsV2, TransactionInfo } from "@/widgets/transaction";

type Emits = {
  backToPaymentPage: [];
  changeTransactionStatus: [value: TransactionStatusKey];
};

interface Props {
  transaction: Transaction;
  transactionStatus: TransactionStatusKey;
  translations: TranslationsV2;
}

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const { paymentMethod } = storeToRefs(usePaymentMethodStore());

const { isLegacyDesign } = storeToRefs(useThemeStore());

const { user } = storeToRefs(useUserStore());

const { amount } = storeToRefs(useFormStore());

const { details } = useTransaction({
  amount,
  isLegacyDesign,
  paymentMethod,
  transaction: toRef(() => props.transaction),
  transactionStatus: toRef(() => props.transactionStatus),
  user,
});

const content = computed<Record<TransactionStatusKey, TransactionContentV2>>(() => ({
  accepted: {
    buttons: [
      {
        callback: () => bridgeService.notify({ messageType: "onClose" }),
        props: {
          color: "primary",
        },
        text: props.translations.accepted.button,
      },
    ],
    component: h("img", {
      src: ProcessImg,
    }),
    text: props.translations.accepted.text,
    title: props.translations.accepted.title,
  },
  mistaked: {
    buttons: [
      {
        callback: () => onBackToPaymentPage(),
        props: {
          color: "primary",
        },
        text: props.translations.mistaked.button,
      },
    ],
    component: h("img", {
      src: WarningImg,
    }),
    text: props.translations.mistaked.text,
    title: props.translations.mistaked.title,
  },
  processed: {
    buttons: [
      {
        callback: () => bridgeService.notify({ messageType: "onClose" }),
        props: {
          color: "primary",
        },
        text: props.translations.processed.button,
      },
    ],
    component: h("img", {
      src: SuccessImg,
    }),
    text: "",
    title: props.translations.processed.title,
  },
  waiting: {
    buttons: [],
    component: Spinner,
    text: props.translations.waiting.text,
    title: props.translations.waiting.title,
  },
}));

const contentByStatus = computed(() => content.value[props.transactionStatus]);

const onBackToPaymentPage = () => emit("backToPaymentPage");
const onChangeTransactionStatus = (value: TransactionStatusKey) => emit("changeTransactionStatus", value);

onMounted(() => {
  setTimeout(() => {
    if (props.transactionStatus === transactionStatuses.waiting) {
      onChangeTransactionStatus(transactionStatuses.accepted);
    }
  }, 1_000 * 60);
});
</script>

<template>
  <SectionWrapperV2 :class="$style.root">
    <div :class="$style.container">
      <TransactionInfo
        :class="$style.space"
        :content="contentByStatus"
      />
      <div
        v-if="transactionStatus !== transactionStatuses.waiting"
        :class="$style.wrapper"
      >
        <PaymentStatement
          :class="$style.space"
          :details="details"
          :payment-method="paymentMethod"
          :transaction="transaction"
        />
        <TransactionButtonsV2
          :buttons="contentByStatus.buttons"
          :class="$style.space"
        />
      </div>
    </div>
  </SectionWrapperV2>
</template>

<style module lang="postcss">
.root {
  @add-mixin scrollbar-hidden;

  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-24);
  padding-bottom: var(--spacing-4);
}

.space {
  margin: var(--spacing-0) var(--spacing-4);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-top: auto;
}
</style>
