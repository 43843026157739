import type { Ref } from "vue";
import { computed } from "vue";

import type { PaymentMethod, PaymentMethodBadge } from "@/entities/payment-method";

interface Props {
  isActive: Ref<boolean>;
  paymentMethod: Ref<Nullable<PaymentMethod>>;
}

export const useBadge = (props: Props) => {
  const badges = computed(
    (): Record<NotUndefined<PaymentMethod["badgeType"]>, PaymentMethodBadge> => ({
      1: {
        color: "auto",
        customClass: {
          content: "top-0 right-0",
          icon: "text-mint-700 dark:text-emerald",
          root: "overflow-hidden rounded-2xl",
        },
        icon: "badges/app",
        isVisible: true,
      },
      2: {
        color: "auto",
        customClass: {
          content: "top-0 right-0",
          icon: "text-blue-cyan-500 dark:text-sapphire",
          root: "overflow-hidden rounded-2xl",
        },
        icon: "badges/push",
        isVisible: true,
      },
    }),
  );

  const badge = computed<PaymentMethodBadge>(() => {
    const badgeType = props.paymentMethod.value?.badgeType;

    if (!badgeType) {
      return {
        color: "secondary",
        customClass: {
          content: "-right-1 -top-1 w-6 h-6 rounded-5.25xl shadow-md",
          icon: "text-white",
          root: "",
        },
        icon: "check",
        isVisible: props.isActive.value,
      };
    }

    return badges.value[badgeType];
  });

  return {
    badge,
  };
};
