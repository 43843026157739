<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { SectionButton, SectionWrapper } from "@/entities/section";
import { routeNames } from "@/shared/constants";
import { Container, Icon, Typography } from "@/shared/ui";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
</script>

<template>
  <section>
    <Container>
      <SectionWrapper>
        <div class="flex grow flex-col items-center justify-center">
          <Icon
            class="mb-8 h-36 w-36"
            name="error"
          />
          <Typography
            class="mb-3 text-3xl"
            size="auto"
            variant="h3"
            weight="bold"
          >
            {{ t("error.title") }}
          </Typography>
          <Typography
            class="text-gray-900/[.8] dark:text-white/[.8]"
            size="lg"
          >
            {{ t("error.text") }}
          </Typography>
        </div>
        <SectionButton
          color="secondary"
          @click="router.push({ name: (route.query.backRoute as string | undefined) ?? routeNames.depositListPage })"
        >
          {{ t("button.refresh") }}
        </SectionButton>
      </SectionWrapper>
    </Container>
  </section>
</template>
